<template>
<section>
    <v-container>
        <v-row justify="start">
          <v-toolbar-title
            class="grey--text text--darken-4 font-weight-black my-5 ml-12"
            >
            Registered Remedies List
          </v-toolbar-title>
          <v-spacer />
          <v-text-field
              v-model="search"
              append-icon="search"
              label="Search"
              single-line
              hide-details
          >
          </v-text-field>
           <v-spacer />
          <div class="text-center">
            <v-menu bottom offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  append-icon="filter_alt"
                  v-model="searchResident"
                  label="Filter By Resident"
                  single-line
                  hide-details
                  v-bind="attrs"
                  v-on="on"
                  />
              </template>
              <v-list style="max-height: 100px" v-model="searchResident">
                <v-list-item
                  v-for="(item, index) in residents"
                  :key="index"
                  v-bind="item"
                >
                  <v-list-item-title  @click="remediesByResident(item)">{{ item.fullName }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </v-row>
        <v-data-table
          :headers="headers"
          :items="remedies"
          :options.sync="options"
          :server-items-length="totalRemedies"
          :loading="loading"
          loading-text="Loading ..... Please wait"
          :footer-props="{
            itemsPerPageOptions: [5, 10, 20, 40],
            itemsPerPageText: 'Remedies per page',
          }"
          update:options
          class="elevation-23"
        >
            <template v-slot:top>
                  <v-toolbar flat color="white">
                    <v-dialog v-model="dialog" max-width="500px">

                      <v-card>
                        <v-card-text>
                          <v-container>
                            <v-row>
                              <v-col cols="12" sm="6" md="4">
                                <v-text-field v-model="editedItem.name" label="Remedy name"></v-text-field>
                              </v-col>
                              <v-col cols="12" sm="6" md="4">
                                <v-text-field v-model="editedItem.comment" label="Comment"></v-text-field>
                              </v-col>
                              <v-col cols="12" sm="6" md="4">
                                <label for="quantity-order"> Quantity Given </label><br>
                                <input v-model="editedItem.quantityGiven" type="number" name="quantity-order" id="input"/>
                              </v-col>
                              <v-col cols="12" sm="6" md="4">
                                <v-text-field v-model="editedItem.witness" label="Witness"></v-text-field>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-card-text>

                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="blue darken-1" text  @click="close">Cancel</v-btn>
                          <v-btn color="blue darken-1" text  @click="save">Save</v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-toolbar>
                </template>
                <template v-slot:item.action="{ item }">
                  <v-row justify="space-around">
                      <span class="group pa-2">
                          <v-icon
                            v-if="$can('update')"
                            small
                            class="mr-2"
                            @click="editItem(item)"
                          >
                            edit
                          </v-icon>
                          <v-icon
                            v-if="$can('delete')"
                            small
                            @click="deleteItem(item)"
                          >
                            delete
                          </v-icon>
                            <v-icon
                              v-if="$can('read')"
                              medium  @click="detail_remedies(item)">info</v-icon>
                      </span>
                  </v-row>
                </template>
        </v-data-table>
        <div class="table-footer-prepend d-flex pl-2 align-center"  v-if='selectedId'>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                color="primary"
                class="mr-3"
                v-on="on"
                @click="addNew()"
              >
              Register
              </v-btn>
            </template>
            <span>Register new remedy</span>
          </v-tooltip>
         </div>
    </v-container>
</section>
</template>
<script>
import {  mapActions } from 'vuex'
import remedyApi from '../../services/api/RemedyApi';
import residentApi from '@/services/api/ResidentApi';
export default {
    name: 'remedyList',
    components: {
    },
    data () {
        return {
            dialog: false,
            remedies: [],
            totalRemedies: 0,
            residents:[],
            searchResident:'',
            loading: true,
            options: {},
            search: '',
            residentId: '',
            selectedId: '',
             headers: [
                { text: 'Remedy Name', value: 'name', class: 'success--text font-weight-bold' },
                { text: 'Resident', value: 'resident.fullName', class: 'success--text font-weight-bold' },
                { text: 'Comment', value: 'comment',  sortable: false, class: 'success--text font-weight-bold' },
                { text: 'Quantity Given', value: 'quantity.given', class: 'success--text font-weight-bold' },
                { text: 'Witness', value: 'quantity.witness',  sortable: false, class: 'success--text font-weight-bold' },
                { text: 'Actions', value: 'action', sortable: false, class: 'success--text font-weight-bold' },
            ],
              editedItem: {
                name: '',
                comment: '',
                witness: '',
                quantityGiven: '',
              },
              defaultItem: {
               name: '',
                comment: '',
                witness: '',
                quantityGiven: '',
              },
        }
    },
    methods: {
        ...mapActions([
            'update_remedy',
        ]),
        editItem (item) {
            this.editedItem = Object.assign({}, item)
            this.dialog = true
        },

        deleteItem (item) {
          confirm('Are you sure you want to delete this remedy?') && this.delete_remedy(item)
          const index = this.remedies.indexOf(item)
          this.remedies.splice(index, 1)
        },

        close () {
          this.dialog = false
          setTimeout(() => {
            this.editedItem = Object.assign({}, this.defaultItem)
            this.editedIndex = -1
          }, 300)
        },

        save () {
            delete this.editedItem.resident;
            delete this.editedItem.createdOn;
            delete this.editedItem.modifiedOn;
            delete this.editedItem.user;
            this.update_remedy({...this.editedItem})
          this.close()
        },
        get_registered_remedy_list () {
          this.loading = true
          return new Promise((resolve, reject) => {
              const { sortBy, sortDesc, page, itemsPerPage } = this.options
              if (sortDesc&& sortDesc.length && sortDesc[0] == false){
                var orde = 'asc'
              }
              else{
                 orde = 'desc'
              }
              if (['Admin', 'Administrator', 'Director', 'Supervisor', 'CCSO', 'CEO'].includes(localStorage.getItem('role'))){
                var homeId = ''
              }
              else{
                homeId = localStorage.getItem('homeId')
              }
              remedyApi.getRegisteredRemedy({page: page, count:itemsPerPage, id:'', q:'', orderBy: sortBy && sortBy[0], order: orde})
                .then(remedies => {
                    if (homeId.toString().length > 0 ){
                        this.remedies = remedies.result.filter(remedy => remedy.resident.home.homeId==homeId)
                        this.totalRemedies = this.remedies.length
                    }
                    else{
                        this.remedies = remedies.result
                        this.totalRemedies = remedies.count
                    }
                })
                .catch(error => {
                    return reject(error)
                })
              setTimeout(() => {
                this.loading = false
                }, 1000)
          })
        },
        delete_remedy(item) {
            remedyApi.deleteRegisteredRemedy(item.id)
                .then(remedy => {
                    return ("deleted" + remedy);
                })
        },
        detail_remedies(remedies) {
          this.$router.push({ name: 'remedyDetail', params: {residentId:remedies.resident.id, remediesId:remedies.id}})
        },
        get_resident_list () {
              return new Promise((resolve, reject) => {
                  if (['Admin', 'Administrator', 'Director', 'Supervisor', 'CCSO', 'CEO'].includes(localStorage.getItem('role'))){
                    var homecode = ''
                  }
                  else{
                    homecode = localStorage.getItem('homeCode')
                  }
                  residentApi.getResidents({page: '', count:'', home:homecode, q:''})
                    .then(residents => {
                        this.residents = residents.result
                        this.totalResidents = residents.count
                    }).catch(error => {
                        return reject(error)
                    })
                  setTimeout(() => {
                    this.loading = false
                    }, 1000)
              })
        },
      remediesByResident(resident) {
          var id = resident.id;
          this.selectedId = resident.id;
          this.searchResident = resident.fullName
          return new Promise((resolve, reject) => {
                  remedyApi.getRegisteredRemedy({page:'', count:'', id:id, q:''})
                    .then(remedies => {
                      this.remedies = remedies.result
                      this.totalRemedies = remedies.count
                    })
                    .catch(error => {
                      return reject(error)
                    })
                })
      },
      addNew() {
        this.$router.push({ name: 'registerRemedy', params: {residentId:this.selectedId}});
      }
    },
    create() {
        this.residentId = this.$route.params.residentId;
        this.get_registered_remedy_list();
    },
    beforeMount(){
      this.get_resident_list();
    },
    watch: {
        options: {
          handler () {
            this.get_registered_remedy_list()
                .then(data => {
                    this.remedies = data.result
                    this.totalRemedies = data.count
                })
          },
          deep: true,
        },
        '$route.params.residentId': {
          handler () {
            this.residentId = this.$route.params.residentId;
            this.get_registered_remedy_list()
                .then(data => {
                    this.remedies = data.result
                    this.totalRemedies = data.count
                })
          },
          deep: true,
          immediate: true
        },
        dialog (val) {
          val || this.close()
        },
        editedItem: function() {
          this.get_registered_remedy_list()
                .then(data => {
                    this.remedies = data.result
                    this.totalRemedies = data.count
                })
        },
        search: function() {
          this.searchResident = ''
          this.selectedId = ''
          if(this.search.length > 0){
                  if (['Admin', 'Administrator', 'Director', 'Supervisor', 'CCSO', 'CEO'].includes(localStorage.getItem('role'))){
                    var homeId = ''
                  }
                  else{
                    homeId = localStorage.getItem('homeId')
                  }
                 return new Promise((resolve, reject) => {
                      remedyApi.getRegisteredRemedy({page:'', count:'', id:'', q:this.search})
                        .then(remedy => {
                            if (homeId.toString().length > 0 ){
                        this.remedies = remedy.result.filter(rem => rem.resident.home.homeId==homeId)
                        this.totalRemedies = this.remedies.length
                    }
                    else{
                        this.remedies = remedy.result
                        this.totalRemedies = remedy.count
                    }
                        })
                        .catch(error => {
                            return reject(error)
                        })
                  })
          }
          if(this.search.length <= 0){
            this.get_registered_remedy_list()
                .then(data => {
                    this.remedies = data.result
                    this.totalRemedies = data.count
                })
          }
        },
        searchResident: function(){
            if(this.searchResident.length <= 0){
              this.selectedId = ''
            this.get_registered_remedy_list()
                .then(data => {
                    this.remedies = data.result
                    this.totalRemedies = data.count
                })
            this.get_resident_list();
            }
            else if(this.searchResident.length >0){
              return new Promise((resolve, reject) => {
                  if (['Admin', 'Administrator', 'Director', 'Supervisor', 'CCSO', 'CEO'].includes(localStorage.getItem('role'))){
                    var homecode = ''
                  }
                  else{
                    homecode = localStorage.getItem('homeCode')
                  }
                  residentApi.getResidents({page:'', count:'', home:homecode, q:this.searchResident})
                    .then(residents => {
                        this.residents = residents.result
                        this.totalResidents = residents.count
                    }).catch(error => {
                        return reject(error)
                    })
                  setTimeout(() => {
                    this.loading = false
                    }, 1000)
              })
          }
        },
    },
}
</script>
<style lang="scss">
  #input {
    width:70%;
  }
  .table-footer-prepend {
    margin-top: -58px;
    height: 58px;
    margin-left: 400px;
  }
</style>
